<template>
  <v-data-table
    :headers="headers"
    :items="onlineCourses"
    mobile-breakpoint="900"
    item-key="name"
    class="elevation-1"
    :search="search"
    :footer-props="{
      itemsPerPageText: this.$t('table.rowNumber'),
    }"
    :loading="loadingCourses"
  >
    <template v-slot:top>
      <div class="d-flex flex-column flex-sm-row justify-end align-center">
        <v-text-field
          v-model="search"
          :label="$t('navBar.search')"
          class="mx-4"
          :prepend-inner-icon="searchIcon"
          single-line
        ></v-text-field>
        <slot></slot>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row align="center" justify="center" class="pa-5">
        <slot name="action" :data="item"></slot>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BactTable",
  props: {
    headers: Array,
    actionName: String,
    totalCount: String,
    loadingTable: Boolean,
    loadingTableText: String,
  },
  data() {
    return {
      search: "",
    
    };
  },
  created() {
    this.fetchData();
   
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch(`${this.actionName}`);
    }
  },

  computed: {
    ...mapState("Courses", ["loadingCourses", "onlineCourses"]),
  },
};
</script>
