var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"bills"}},[_c('notifications',{style:({ marginBottom: '2px' }),attrs:{"group":"public","position":"bottom center"}}),(_vm.add_Course || _vm.edit_Course.course_id >= 0)?_c('dialog-courses',_vm._b({on:{"handelRequest":_vm.handelRequest,"handleClose":_vm.handleClose}},'dialog-courses',{
      categories: _vm.category,
      trainingPaths: _vm.trainingPaths,
      instructors: _vm.instructor,
      editCourse: _vm.edit_Course,
      edit: _vm.edit,
    },false)):_vm._e(),(_vm.deleteConfirm >= 0)?_c('delete-courses-confirm',_vm._b({on:{"handleCancel":_vm.handleClose,"handelRequest":_vm.removeCourse}},'delete-courses-confirm',{ id: _vm.deleteConfirm, isOpen: true },false)):_vm._e(),_c('v-row',{staticClass:"d-flex flex-column text-center"},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3 font-weight-bold mb-3 text-grey"},[_vm._v(" "+_vm._s(_vm.$t("adminCourses.courses"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('base-tables',_vm._b({scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.primaryColor,"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openModules(data.course_id, data.course_status)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("adminCourses.module")))])],1),(data.course_status !== 'denied')?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'courseForm', params: { id: data.course_id } }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("adminCourses.edit")))])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.deleteConfirm = data.course_id}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("form.delete")))])],1)],1)],1)],1)]}}])},'base-tables',{
          headers: _vm.headers,
          actionName: 'Courses/FetchCourses',
        },false),[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'courseForm', params: { id: -1 } }}},[_c('v-btn',{staticClass:"ml-3 mb-3 mb-md-0 text-lg-h6",attrs:{"color":_vm.YColor,"dark":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.plusIcon))]),_vm._v(" "+_vm._s(_vm.$t("adminCourses.newCourse"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }