<template>
  <v-container id="bills">
    <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />

    <dialog-courses
      v-if="add_Course || edit_Course.course_id >= 0"
      v-bind="{
        categories: category,
        trainingPaths: trainingPaths,
        instructors: instructor,
        editCourse: edit_Course,
        edit: edit,
      }"
      @handelRequest="handelRequest"
      @handleClose="handleClose"
    ></dialog-courses>

    <delete-courses-confirm
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleCancel="handleClose"
      @handelRequest="removeCourse"
    ></delete-courses-confirm>

    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          {{ $t("adminCourses.courses") }}
        </h1>
      </v-col>

      <v-col cols="12">
        <base-tables
          v-bind="{
            headers: headers,
            actionName: 'Courses/FetchCourses',
          }"
        >
          <router-link
            :to="{ name: 'courseForm', params: { id: -1 } }"
            style="text-decoration: none"
          >
            <v-btn class="ml-3 mb-3 mb-md-0 text-lg-h6" :color="YColor" dark>
              <v-icon>{{ plusIcon }}</v-icon>
              {{ $t("adminCourses.newCourse") }}
            </v-btn>
          </router-link>

          <template v-slot:action="{ data }">
            <div class="text-center">
              <v-menu offset-y open-on-hover min-width="150">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="primaryColor"
                    icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon large>{{ settingsIcon }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  
                    <v-list-item @click="openModules(data.course_id, data.course_status)">
                      <v-list-item-title>{{
                        $t("adminCourses.module")
                      }}</v-list-item-title>
                    </v-list-item>

                  <router-link
                    v-if="data.course_status !== 'denied'"
                    :to="{ name: 'courseForm', params: { id: data.course_id } }"
                    style="text-decoration: none"
                  >
                    <v-list-item>
                      <v-list-item-title>{{
                        $t("adminCourses.edit")
                      }}</v-list-item-title>
                    </v-list-item>
                  </router-link>

                  <v-list-item @click="deleteConfirm = data.course_id">
                    <v-list-item-title>{{
                      $t("form.delete")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </base-tables>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";
import baseTables from "./Components/courses_BaseTable";
import dialogCourses from "./Components/dialog_courses";
import deleteCoursesConfirm from "./Components/confirmDelete";
export default {
  components: { baseTables, dialogCourses, deleteCoursesConfirm },
  data() {
    return {
      headers: [
        { text: this.$t("adminCourses.courseTitle"), value: "course_title" },
        {
          text: this.$t("adminCourses.categoryTitle"),
          value: "category_title",
        },
        { text: this.$t("adminCourses.type"), value: "course_type" },
        { text: this.$t("table.status"), value: "course_status" },
        { text: this.$t("adminCourses.enrolls"), value: "total_enrolls" },
        { text: this.$t("adminCourses.completed"), value: "total_completed" },
        { text: this.$t("adminCourses.price"), value: "course_price" },
        { text: "", value: "actions", align: "center" },
      ],
      add_Course: false,
      deleteConfirm: -1,
      edit_Course: {
        course_id: -1,
      },
      edit: false, // to use as a prop in dialog
    };
  },
  methods: {
    ...mapActions("Courses", [
      "FetchTrainingPaths",
      "fetchInstructors",
      "FetchCategory",
      "addCourse",
      "updateCourse",
      "deleteCourse",
      "FetchCourses",
    ]),

    created() {
      this.FetchCourses().then((res) => {
        console.log(res);
      });
    },

    openModules (id, status) {
      if (status === 'denied') {
        localStorage.setItem('courseStatus', 'denied')
      } else {
        localStorage.setItem('courseStatus', 'notDenied')
      }
      this.$router.push({name: 'editCourseModule', params: { id: id }, query: { type: 'module' } }) },

    handelEdit(data) {
      console.log(data);
      this.edit = true;
      this.edit_Course = data;
    },

    async fetchDependency() {
      await this.FetchTrainingPaths();
      await this.fetchInstructors();
      await this.FetchCategory();
    },

    async handelRequest(data) {
      await this[data.method](data.formdata);
    },

    handleClose() {
      this.add_Course = false;
      this.deleteConfirm = -1;
      this.edit_Course = {};
      this.edit = false;
    },

    removeCourse(payload) {
      this.deleteCourse({ id: payload.id });
      this.handleClose();
    },
  },
  created() {
    this.fetchDependency();
  },
  computed: {
    ...mapState("Courses", ["category", "instructor", "trainingPaths"]),
  },
};
</script>


<style scoped>
</style>